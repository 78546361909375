import { ICommandArgs } from "../types"
import { Workbench } from "../../workbench/Workbench"
import { vscodeFileSystemListener } from "./fileSystemListener"
import { vscodeFileSearchListener } from "./fileSearchListener"
import { vscodeTextSearchListener } from "./textSearchListener"

export const listenToVsCodeEvent = (
  payload: ICommandArgs,
  fileTree: Workbench,
  contentWindow: Window
) => {
  vscodeFileSystemListener(payload, fileTree, contentWindow)
  vscodeFileSearchListener(payload, fileTree, contentWindow)
  vscodeTextSearchListener(payload, fileTree, contentWindow)
}
