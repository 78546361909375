import React from "react"
import { ErrorDialogProps } from "./ErrorDialog"

type ErrorSnackbarProps = ErrorDialogProps

const ErrorSnackbar: React.FC<ErrorSnackbarProps> = ({
  backendErrors,
  setErrorDialog,
}) => (
  //@ts-ignore
  <mwc-snackbar
    leading={true}
    open={backendErrors}
    timeoutMs={-1}
    labelText={`Error while ${
      backendErrors?.bundlerError ? "bundling." : "transpiling."
    }`}
  >
    {/* @ts-ignore */}
    <mwc-button
      primary
      raised
      style={{ "--mdc-snackbar-action-color": "#3380ff" }}
      slot="action"
      onClick={() => setErrorDialog(true)}
    >
      Show
      {/* @ts-ignore */}
    </mwc-button>
    {/* @ts-ignore */}
  </mwc-snackbar>
)

export default ErrorSnackbar
