import { DevToolsHook } from "@iteria-app/react-lowcode/esm/devtools"

interface OwnersProps{
  displayName: string
  id: number
  type: number
}

export function findTypenameFromTable(inspectedElOwners:OwnersProps[],devtoolsHook: DevToolsHook | undefined | null,){
  const rendererInterface =  (devtoolsHook?.reactDevtoolsAgent as any)._rendererInterfaces["1"]

  for(let item of inspectedElOwners){
    const path = rendererInterface?.getPathForElement(item)
    const data = rendererInterface?.inspectElement(item.id,path)
    const tableData = data?.value?.props?.data

    if(tableData != null){
      const tableRows = tableData?.rows
      if (tableRows != undefined){
        const tableTypename = tableRows[0]?.preview_long
        const typenameRaw = tableTypename.split(`"`)
        if(typenameRaw.length > 1) {
          const typename = typenameRaw[1]?.charAt(0).toUpperCase() + typenameRaw[1]?.slice(1) 
          return typename
        }
      }
    }     
  }
  return ""
}