import { Project } from "ts-morph"
import * as Comlink from "comlink"

import { transpileFiles, transpileSingleFile } from "../codeHandlers/transpile"
import { IFiles } from "../types"
import { messagingService } from "../App"
import {
  bundleFilesAction,
  perfromReactRefreshAction,
} from "../messaging/actions"
import {
  BUNDLE_FILES,
  MessageType,
  TRANSPILE_FILES,
  TRANSPILE_SINGLE_FILE,
} from "../messaging/messageTypes"
import { stripExtension } from "../codeHandlers/strip"

export type BundleFilesData = { files: IFiles; bundleEntry?: string }
export type TranspileFilesData = IFiles
export interface TranspileSingleFileData {
  path: string
  data: string
  files: IFiles
}

interface BundlerError {
  type: MessageType
  payload: Error
}
interface IBundler {
  bundleFiles: (
    files: IFiles,
    bundleEntry?: string
  ) => Promise<string | BundlerError>
}

export class BundleWorker {
  constructor(project: Project) {
    this.bundler = Comlink.wrap(new Worker("worker.js"))
    this.project = project

    messagingService.addEventListener<BundleFilesData>(
      BUNDLE_FILES,
      async ({ files, bundleEntry }) => {
        const bundle = await this.bundleFiles(files, bundleEntry)
        if (bundle) perfromReactRefreshAction(bundle)
      }
    )

    messagingService.addEventListener<TranspileFilesData>(
      TRANSPILE_FILES,
      async files => {
        const transpiledFiles = await this.transpileFiles(files)
        bundleFilesAction(transpiledFiles)
      }
    )

    messagingService.addEventListener<TranspileSingleFileData>(
      TRANSPILE_SINGLE_FILE,
      async ({ files, path, data }) => {
        const transpiledFile = await this.transpileSingleFile(path, data)
        if (transpiledFile) {
          const newPath = path.endsWith(".json") ? path : stripExtension(path)
          const newFiles = { ...files, [newPath]: transpiledFile }
          bundleFilesAction(newFiles, newPath)
        }
      }
    )
  }

  private bundler: Comlink.Remote<IBundler>
  private project: Project

  async bundleFiles(files: IFiles, bundleEntry?: string) {
    console.time("bundle")
    const bundle = await this.bundler.bundleFiles(files, bundleEntry)
    if (typeof bundle !== "string") {
      messagingService.postMessage(bundle.type, { err: bundle.payload })
      return null
    }
    console.timeEnd("bundle")
    return bundle
  }

  async transpileAndBundleFiles(files: IFiles) {
    const transpiledFiles = await this.transpileFiles(files)
    const bundle = await this.bundleFiles(transpiledFiles)
    return bundle
  }
  async transpileFiles(files: IFiles) {
    const transpiledFiles = await transpileFiles(files, this.project)
    return transpiledFiles
  }

  async transpileSingleFile(path: string, data: string) {
    console.time("transpile")
    const transpiledFile = await transpileSingleFile(path, data, this.project)
    console.timeEnd("transpile")
    return transpiledFile
  }
}
