export const projectUrls = {
  "github.com/iteria-app/example-material-ui": {
    vercelUrl: "https://skuska-materail-example-ui.vercel.app",
    gitHubUrl:
      "https://api.github.com/repos/mecirmartin/example-material-app/git/trees/aff7d13da53ac5f12888555126252b45a98a5a74",
    editorEntryPoint: "/src/views/reports/DashboardView/Budget.tsx",
  },
  "github.com/iteria-app/example-ionic-react": {
    vercelUrl: "https://example-ionic-react.vercel.app",
    gitHubUrl:
      "https://api.github.com/repos/iteria-app/example-ionic-react/git/trees/651d6600f5816e70ce11b9cb5b1ec984f8eee0e3",
    editorEntryPoint: "/src/index.tsx",
  },
  "github.com/iteria-app/example-grommet": {
    vercelUrl: "https://example-grommet.vercel.app",
    gitHubUrl:
      "https://api.github.com/repos/iteria-app/example-grommet/git/trees/bfe25510c97745b860c3bc1b55b5ddad34d87642",
    editorEntryPoint: "/src/components/VirtualMachinesCard.tsx",
  },
}

export type ProjectUrlKeys = keyof typeof projectUrls
