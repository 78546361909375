import prettier from "prettier/standalone"
import typescriptParser from "prettier/parser-typescript"

export const formatCode = (code: string) => {
  const formattedCode = prettier.format(code, {
    parser: "typescript",
    plugins: [typescriptParser],
  })

  return formattedCode
}
