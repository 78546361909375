import {
  initialize as initializeBackend,
  activate as activateBackend,
  //@ts-ignore
} from "react-devtools-inline/backend"
//@ts-ignore
import { initialize as initializeFrontend } from "react-devtools-inline/frontend"

export const initializeDevtools = (
  iframe: HTMLIFrameElement,
  setDevTools: (ReactDevTools: any) => void
) => {
  if (iframe.contentWindow) {
    const { contentWindow } = iframe

    setDevTools(initializeFrontend(contentWindow))
    initializeBackend(contentWindow)
    activateBackend(contentWindow)
  }
}
