import {
  SourceLineCol,
  startOfJsxIdentifier,
  astFindStart,
} from "@iteria-app/react-lowcode/esm/ast/find"
import { ImportDeclaration, Project, SourceFile } from "ts-morph"

export const changeImports = (sourceFile: SourceFile, filePath: string) => {
  const imports = sourceFile.getImportDeclarations()

  imports.forEach(i => {
    let importString = i.getModuleSpecifierValue()
    // TODO Node resolve
    if (importString === "./" || importString === ".") {
      i.setModuleSpecifier("./index")
    }

    if (!i.isModuleSpecifierRelative() && importString.startsWith("src/")) {
      // Here i am processing absolute imports
      // https://dev.to/mr_frontend/absolute-imports-in-create-react-app-3ge8
      let relativePath = absoluteToRelativePath(
        prefixString(importString, "/"),
        filePath
      )

      i.setModuleSpecifier(relativePath)
    } else if (
      !i.isModuleSpecifierRelative() &&
      !importString.endsWith(".css")
    ) {
      replaceDependencyImports(i)
    } else if (importString.endsWith(".css")) {
      // Comment out CSS Imports
      i.replaceWithText(`//${i.getText()}`)
    }
  })
  return sourceFile.print()
}

const absoluteToRelativePath = (absolutePath: string, currentPath: string) => {
  const folders = currentPath.split("/")
  let relativePath = ""

  folders.pop()
  const current = folders[folders.length - 1]
  if (absolutePath.match(`/${current}/`)) {
    const substring = absolutePath.substring(
      absolutePath.lastIndexOf(`/${current}/`) + `/${current}/`.length
    )
    return "./" + substring
  } else {
    relativePath += "../"
    folders.pop()
  }

  while (folders.length > 0) {
    const current = folders.pop()
    if (absolutePath.match(`/${current}/`)) {
      const substring = absolutePath.substring(
        absolutePath.lastIndexOf(`/${current}/`) + `/${current}/`.length
      )
      return relativePath + substring
    } else {
      relativePath += "../"
    }
  }
  return absolutePath
}

const replaceDependencyImports = (i: ImportDeclaration) => {
  const slashedDependency = i
    .getModuleSpecifierValue()
    .split("/")
    .map(d => `['${d}']`)
    .join("")
  const defaultImport = `const ${
    i.getDefaultImport()?.print() ?? i.getNamespaceImport()?.print()
  } =  window.__deps${slashedDependency};`
  const namedImports = `const { ${i
    .getNamedImports()
    .map(imp => imp.print().replaceAll(/as/g, ":"))
    .join(",")} } = window.__deps${slashedDependency};`

  if (i.getNamedImports().length && i.getDefaultImport()) {
    i.replaceWithText(defaultImport + "\n" + namedImports)
  } else if (i.getDefaultImport() || i.getNamespaceImport()) {
    i.replaceWithText(defaultImport)
  } else if (i.getNamedImports()) {
    i.replaceWithText(namedImports)
  }
}

const prefixString = (string: string, prefix: string) =>
  string.startsWith(prefix) ? string : prefix + string

export const deleteAllFilesFromProject = async (project: Project) => {
  const sourceFiles = project.getSourceFiles()
  const promiseArray: Array<Promise<void>> = []
  sourceFiles.forEach(f => promiseArray.push(f.deleteImmediately()))

  Promise.all(promiseArray)
}

export const changeImportsBundle = (sourceFile: SourceFile) => {
  const imports = sourceFile.getImportDeclarations()

  imports.forEach(i => {
    const importString = i.getModuleSpecifierValue()
    if (importString === "./" || importString === ".") {
      i.setModuleSpecifier("./index")
    }

    if (
      !i.isModuleSpecifierRelative() &&
      !i.getModuleSpecifierValue().endsWith(".css")
    ) {
      replaceDependencyImports(i)
    }
  })

  return sourceFile.print()
}

export const getJsxIdentifierLength = (code: string, source: SourceLineCol) => {
  const pos = startOfJsxIdentifier(code, source)
  const node = astFindStart(code, pos!)
  if (!node) throw new Error("Unable to find node in AST")
  return node.end - node.pos
}
