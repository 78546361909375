import { cloneElementInAst } from "@iteria-app/react-lowcode/esm/clone"
import { SourceLineCol } from "@iteria-app/react-lowcode/esm/ast/find"

import { encodeEmptyLines, printFormattedSourceFile } from "./encode"

export const cloneElement = (code: string, source: SourceLineCol) => {
  // Encode empty lines as comment so ts-compiler won't remove them
  const encoded = encodeEmptyLines(code)
  const alteredAst = cloneElementInAst(encoded, source)
  if (!alteredAst) return console.error("Unable to clone Element in AST")

  const newCode = printFormattedSourceFile(alteredAst)
  return newCode
}
