import { delay } from "../util/delay"

export const windowHasDeps = async (contentWindow: Window) => {
  while (!contentWindow.__deps) {
    await delay(5)
  }
}

export const windowHasNoDeps = async (contentWindow: Window) => {
  while (contentWindow.__deps) {
    await delay(5)
  }
}
