import { Workbench } from "../../workbench/Workbench"
import { ICommandArgs } from "../types"

export const vscodeTextSearchListener = async (
  args: ICommandArgs,
  fileTree: Workbench,
  contentWindow: Window
) => {
  const { payload, command, id } = args
  if (command === "textSearch") {
    const { query } = payload
    const searchIndex = fileTree.getSearchIndex()
    const result = (await searchIndex.search(query.pattern)) as any
    const data = result.map((r: number) => fileTree.getFileMapEntry(r))

    contentWindow?.postMessage({ id, command, payload: data }, "*")
  }
}
