import { useState, useEffect } from "react"
import { RouteComponentProps } from "react-router-dom"
import qs from "qs"
import SplitPane from "react-split-pane"

import { useMainContext, useSplitPaneContext } from "./context/context"
import ProjectPicker from "./components/ProjectPicker/ProjectPicker"
import {
  IFRAME_SRC_LOADING,
  TOOLBAR_OPTIONS,
  VSCODE_IFRAME_ID,
} from "./util/constants"
import { ProjectUrlKeys } from "./util/projectUrls"
import { projectUrls } from "./util/projectUrls"
import {
  useTranspileFilesListener,
  useBundleFilesListener,
  useRefreshIframeListener,
  useIframeRefreshedListener,
  useBundlerErrorListener,
  useTranspilerErrorListener,
  usePerformReactRefreshListener,
  useCloneElementListener,
  useConnectDevtoolsListener,
  useConnectElementHighlightListener,
  useNavigateToCodeListener,
  useProjectLoadedListener,
  useRemoveElementListener,
} from "./messaging/customMessagingHooks"
import ErrorDialog from "./components/ErrorDialog"
import ErrorSnackbar from "./components/ErrorSnackbar"
import {
  fileWriteAction,
  generateGraphql,
  getProjectZipAction,
  refreshIframeAction,
  transpileFilesAction,
} from "./messaging/actions"
import { listenToVsCodeEvent } from "./vscode/listeners"
import { URI } from "vscode-uri"
import { cloneElement } from "./ast/clone"
import { removeElement } from "./ast/remove"
import { initializeDevtools } from "./devtools"
import { changeImports, getJsxIdentifierLength } from "./ts-morph/ast"
import { IFRAME_VSCODE_SRC } from "./util/constants"
import "@material/mwc-linear-progress"
import "@material/mwc-snackbar"
import "@material/mwc-button"
import { CodeDir, CodeRW } from "@iteria-app/react-lowcode/esm/io"

import {
  tryAddColumnToDatatable,
  tryAddFormWidget,
  tryDeleteColumn,
} from "./codegen/generator"
import {
  generatePages,
  isSelectedDataTable,
  isSelectedFormWidget,
} from "@iteria-app/react-lowcode/esm/codegen"

import { addElementHighlight } from "./iframe/elementHighlight"
import { fetchGraphqlIntrospectionSchema, getURLFromConfig } from "./graphql/generateGraphqlTypesFile"
import { TableType } from "@iteria-app/react-lowcode/esm/codegen/definition/context-types"
import path from 'path'

const listPageTemplate = `import { useGeneratedQuery } from '../generated'
import Fetching from './Fetching'
import Error from './Error'
import * as React from "react"
import ListPlaceholder from './ListPlaceholder'
function App() {
  const [result] = useGeneratedQuery({
    variables: {}
  })
  const { fetching, error, data } = result
  if (fetching) return <Fetching />;
  if (error) return <Error error={error} />;
  return (
    <ListPlaceholder customers={data?.customers} />
  );
}
export default App;`

const detailPageTemplate = `import { #query_name# } from "src/generated/graphql";
import Fetching from './Fetching';
import Error from './Error';
import * as React from "react";
import { #detail_component# } from "./#detail_component#"

function #detail_component_page#() {
    const [result] = #query_name#({
        variables: {}
    });
    const { fetching, error, data } = result;
    if (fetching)
        return <Fetching />;
    if (error)
        return <Error error={error}/>;
		
    return (<#detail_component# {...data?.#entity_identifier#[0]} />);
}
export default #detail_component_page#;`

const stylesHorizontal = {
  background: "#000",
  width: "100%",
  cursor: "row-resize",
  margin: "5px",
  height: "2px",
}
const stylesVertical = {
  background: "#000",
  width: "2px",
  cursor: "col-resize",
  margin: "5px",
  height: "100%",
}

enum BackendState {
  Idle,
  Transpiling,
  Bundling,
  Refreshing,
}

export interface BackendErrors {
  transpilerError?: Error
  bundlerError?: Error
}

export class TransactionRw implements CodeRW, CodeDir {
  
  private _delegate: CodeRW & CodeDir
  private _fileMap: Map<string, string>

  constructor(delegate: CodeRW & CodeDir){
      this._delegate = delegate
      this._fileMap = new Map<string, string>()
  }

  readDirectory(path: string, extensions?: readonly string[], exclude?: readonly string[], include?: readonly string[], depth?: number): Promise<string[] | undefined> {
    return this._delegate.readDirectory(path, extensions, exclude, include, depth)
  }

  readFile(filePath: string, encoding?: string): Promise<string | undefined> {
       if(this._fileMap.get(filePath)){
         return Promise.resolve(this._fileMap.get(filePath))
       } else{
         return this._delegate.readFile(filePath, encoding)
       }
  }

  writeFile(filePath: string, data: string): Promise<void> {
    const extension = path.extname(filePath)

    if(extension === '.qraphql'){
        return this._delegate.writeFile(filePath, data)
    }else{
        this._fileMap.set(filePath, data)
        return Promise.resolve()
    }
  }

  commit(): void{
    for (let key of this._fileMap.keys()) {  
          const data = this._fileMap.get(key)
          if(data){
              this._delegate.writeFile(key, data)
          }
    }  

    setTimeout(() => {for (let key of this._fileMap.keys()) {  
      const data = this._fileMap.get(key)
      if(data){
        console.log(`saving file2: ${key}`)
          this._delegate.writeFile(key, data + ' ')
      }
    }}, 200)
    
    this._fileMap = new Map<string, string>()
  }
}

const Workbench = (props: RouteComponentProps) => {
  const [isDragging, setIsDragging] = useState(false)
  const [repo, setRepo] = useState<ProjectUrlKeys>()
  const [ReactDevTools, setDevTools] = useState(null)
  const [showEditor, setShowEditor] = useState(false)

  const [backendState, setBackendState] = useState<BackendState>(
    BackendState.Idle
  )
  const [backendErrors, setBackendErrors] = useState<BackendErrors | null>()
  const [errorDialog, setErrorDialog] = useState(false)

  const [changeEditor, setChangeEditor] = useState<TOOLBAR_OPTIONS>()
  const {
    mainContext: { iframe, workbench, bundler, tsMorphProject },
  } = useMainContext()
  const { splitPaneContext } = useSplitPaneContext()
  const { isVertical } = splitPaneContext

  useBundlerErrorListener(({ err }) =>
    setBackendErrors({ ...backendErrors, bundlerError: err })
  )
  useTranspilerErrorListener(({ err }) =>
    setBackendErrors({ ...backendErrors, transpilerError: err })
  )
  useTranspileFilesListener(() => setBackendState(BackendState.Transpiling))
  useBundleFilesListener(() => setBackendState(BackendState.Bundling))
  useRefreshIframeListener(() => setBackendState(BackendState.Refreshing))
  usePerformReactRefreshListener(() => setBackendState(BackendState.Refreshing))
  useIframeRefreshedListener(() => {
    // Clear backend errors when iframe is refreshed
    setBackendErrors(null)
    setBackendState(BackendState.Idle)
  })

  useConnectDevtoolsListener(() =>
    initializeDevtools(iframe.getIframe(), setDevTools)
  )

  useConnectElementHighlightListener(() =>
    addElementHighlight(iframe.getIframe().contentWindow!)
  )

  useProjectLoadedListener(({ files }) => {
    setChangeEditor(TOOLBAR_OPTIONS.VSCODE)
    setShowEditor(true)
    transpileFilesAction(files)
  })

  useCloneElementListener(async (source: any) => {
    const { fileName, colIndex, columnToAdd, typename } = source
    const file = await workbench.readFile(fileName)
    if (!file) throw new Error("File not found in Workbench")
    let changedCode = undefined

    if (isSelectedDataTable(file, source)) {
      changedCode = await tryAddColumnToDatatable(file, source, workbench, colIndex, columnToAdd, typename)
    } else if (isSelectedFormWidget(file, source)) {
      changedCode = await tryAddFormWidget(file, source, workbench, colIndex, columnToAdd)
    } else {console.log('isSelectedFormWidget: not')
      changedCode = cloneElement(file, source)
    }

    if (!changedCode) return

    fileWriteAction(fileName, changedCode)
  })

  useRemoveElementListener(async source => {

    const { fileName } = source
    const file = await workbench.readFile(fileName)
    if (!file) throw new Error("File not found in Workbench")

    let changedCode = undefined
    // TODO Refactor the type, refactor the data flow of program

    if (isSelectedDataTable(file, source)) {
      //@ts-ignore
      const columnIndex = source.colIndex - 1 // replace this by selected column with index starting with 0
      changedCode = await tryDeleteColumn(file, source, workbench, columnIndex)
    } else {
      changedCode = removeElement(file, source)
    }

    if (!changedCode) return

    fileWriteAction(fileName, changedCode)
  })

  useNavigateToCodeListener(async source => {
    const { fileName, columnNumber, lineNumber } = source
    const file = await workbench.readFile(fileName)
    if (!file) throw new Error("File not found in Workbench")

    const vscodeIframe = document.getElementById(
      VSCODE_IFRAME_ID
    ) as HTMLIFrameElement
    const { contentWindow } = vscodeIframe
    if (!contentWindow) {
      throw new Error("Unable to destructure window from Iframe")
    }

    const identifierLength = getJsxIdentifierLength(file, source) + 1
    // Change focus so changes in file will be immediately displayed
    vscodeIframe.focus()
    contentWindow.postMessage(
      {
        command: "iteria.navigateEditor",
        payload: {
          uri: URI.parse(fileName),
          lineNumber: lineNumber - 1,
          columnNumber: columnNumber - 1,
          identifierLength,
        },
      },
      "*"
    )
  })

  useEffect(() => {
    if (!repo) return
    const vercelUrl = projectUrls[repo].vercelUrl
    iframe.setVercelHtml(vercelUrl)
    getProjectZipAction(repo)
  }, [repo])

  useEffect(() => {
    const parsedParams = qs.parse(window.location.search)
    const repository = parsedParams["?repo"] as ProjectUrlKeys
    if (!repository) {
      return
    }
    if (!(repository in projectUrls)) {
      return alert("Url is not a valid Iteria-UI repository")
    }

    setRepo(repository)
  }, [])

  const handleGeneratePage = async () => {
    if (!repo) return
    
    var options = { 
      names: ["products"], 
      pageListTemplate: listPageTemplate,
      detailPageTemplate: detailPageTemplate,
      tableType: TableType.DataTable
    }

    const URLSchema = await getURLFromConfig(workbench)
    const schema = await fetchGraphqlIntrospectionSchema(
      URLSchema
    )
    
    const rw = new TransactionRw(workbench)

    await generatePages(schema, rw, options)
    console.log('generating ended')
    rw.commit()
  }

  return repo ? (
    <>
      <SplitPane
        split={isVertical ? "vertical" : "horizontal"}
        minSize={50}
        maxSize={isVertical ? "98%" : "100%"}
        defaultSize={"50%"}
        resizerStyle={isVertical ? stylesVertical : stylesHorizontal}
        primary="second"
        onDragStarted={() => setIsDragging(true)}
        onDragFinished={() => setIsDragging(false)}
        pane2Style={{
          overflow: "scroll",
          display: splitPaneContext.display ? "block" : "none",
        }}
      >
        <>
          {backendState !== BackendState.Idle && (
            /* @ts-ignore */
            <mwc-linear-progress
              progress={backendState * 0.25}
            /* @ts-ignore */
            ></mwc-linear-progress>
          )}
          <ErrorSnackbar
            backendErrors={backendErrors}
            setErrorDialog={setErrorDialog}
          />
          {errorDialog && (
            <ErrorDialog
              backendErrors={backendErrors}
              setErrorDialog={setErrorDialog}
            />
          )}
          <iframe
            title="iframeElement"
            id="iframe"
            src={IFRAME_SRC_LOADING}
            width="100%"
            style={{
              height: "100%",
              pointerEvents: isDragging ? "none" : "auto",
            }}
            frameBorder="0"
          ></iframe>
        </>
        {showEditor && (
          <>
            {/* @ts-ignore */}
            <mwc-button
              style={{ marginLeft: 20 }}
              outlined
              onClick={() => setChangeEditor(TOOLBAR_OPTIONS.VSCODE)}
            >
              Vscode
              {/* @ts-ignore */}
            </mwc-button>
            {/* @ts-ignore */}
            <mwc-button
              outlined
              style={{ marginLeft: 20 }}
              onClick={() => setChangeEditor(TOOLBAR_OPTIONS.DEV)}
            >
              Devtools
              {/* @ts-ignore */}
            </mwc-button>
            {/* @ts-ignore */}
            <mwc-button
              outlined
              style={{ marginLeft: 20 }}
              onClick={() => generateGraphql()}
            >
              Graphql codegen
              {/* @ts-ignore */}
            </mwc-button>
            {/* @ts-ignore */}
            <mwc-button
              outlined
              style={{ marginLeft: 20 }}
              onClick={() => handleGeneratePage()}
            >
              Generate Page
              {/* @ts-ignore */}
            </mwc-button>
            <span
              style={
                changeEditor !== TOOLBAR_OPTIONS.DEV ? { display: "none" } : {}
              }
            >
              {ReactDevTools && (
                //@ts-ignore
                <ReactDevTools />
              )}
            </span>
            <span
              style={
                changeEditor !== TOOLBAR_OPTIONS.VSCODE
                  ? { display: "none" }
                  : {}
              }
            >
              {
                <iframe
                  src={IFRAME_VSCODE_SRC}
                  id={VSCODE_IFRAME_ID}
                  height="100%"
                  width="100%"
                  style={{
                    pointerEvents: isDragging ? "none" : "auto",
                  }}
                  onLoad={() => {
                    const vscodeIframe = document.getElementById(
                      VSCODE_IFRAME_ID
                    ) as HTMLIFrameElement
                    const { contentWindow } = vscodeIframe
                    if (!contentWindow) {
                      throw new Error(
                        "Unable to destructure window from Iframe"
                      )
                    }

                    window.addEventListener("message", async ({ data }) => {
                      if (!data.command) {
                        return
                      }
                      listenToVsCodeEvent(data, workbench, contentWindow)
                    })
                  }}
                  frameBorder="0"
                ></iframe>
              }
            </span>
          </>
        )}
      </SplitPane>
    </>
  ) : (
    <ProjectPicker />
  )
}

export default Workbench
