import {
  buildClientSchema,
  getIntrospectionQuery,
  parse,
  printSchema,
} from "graphql"
import { generateTypes } from "./generateTypes"
import { isWebUri } from "valid-url"
import { transpileErrorAction } from "../messaging/actions"
import { CodeRW } from "@iteria-app/react-lowcode/esm/io"
import { SearchFile } from "../workbench/Workbench"
import { Codegen } from "./Codegen"
import { IFiles } from "../types"
import { ConfigObject, ConfigProps, DocumentNodeLocation, GraphqlCodeGenerator } from "./types"

async function fetchGraphqlSchema(schema: string) {
  try {
    const response = await fetch(`${schema}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        variables: {},
        query: getIntrospectionQuery(),
      }),
    })
    const { data } = await response.json()
    const buildSchema = buildClientSchema(data)
    const generatedSchema = printSchema(buildSchema)

    return generatedSchema
  } catch (e) {
    console.log(e)
    transpileErrorAction(e)
  }
}

export async function generateGraphqlTypesFile(
  config: string | number | object,
  documents: DocumentNodeLocation[] | undefined
) {
  const { schema } = config as ConfigProps

  if (isWebUri(schema)) {
    const generatedSchema = (await fetchGraphqlSchema(schema)) || ""
    const result = await generateTypes({
      config,
      generatedSchema,
      documents,
    })

    return result
  }
}

export async function getURLFromConfig(workbench: CodeRW) {
  const config = await findGraphqlConfig({workbench})
  const { schema } = config as ConfigProps
  return schema
}

export async function fetchGraphqlIntrospectionSchema(schema: string) {
  try {
    const response = await fetch(`${schema}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        variables: {},
        query: getIntrospectionQuery(),
      }),
    })
    const { data } = await response.json()
    return data.__schema // FIXME hardcoded !!!
  } catch (e) {
    console.log(e)
    transpileErrorAction(e)
  }
}

export async function getGeneratesFileSourceFromConfig(workbench: CodeRW) {
  const config = await findGraphqlConfig({workbench})
  const { generates } = config as ConfigProps
  const genFiles = Object.keys(generates ?? {})
  if (genFiles?.length > 0) {
    return workbench.readFile(genFiles[0])
  }
}

export async function getGeneratesFileNameFromConfig(
  config: string | number | object
) {
  const { generates } = config as ConfigProps
  const genFiles = Object.keys(generates ?? {})
  if (genFiles?.length > 0) {
    return genFiles[0]
  }
}

export async function parseFileMapToFileObject(filesArray: SearchFile[]) {
  const fileObject: IFiles = {}
  filesArray
    .filter((file) => file.uri.path.endsWith(".graphql"))
    .map((data) => {
      fileObject[data.uri.path] = data.data
    })
  return fileObject
}


export async function graphqlCodegen({
  workbench,
  filesObject,
  data,
  path,
}: GraphqlCodeGenerator) {
  const editedDocumentData = data ?? ""
  const currentDocumentPath = path ?? ""
  const config = await findGraphqlConfig({ filesObject, workbench })
  const documents = findDocumentsInProject(
    filesObject,
    editedDocumentData,
    currentDocumentPath
  )
  const generated = (await generateGraphqlTypesFile(config, documents)) ?? ""
  const generatedFilePath = (await getGeneratesFileNameFromConfig(config)) ?? ""

  if (path != undefined) {
    workbench.writeFile(generatedFilePath, generated)
  }
  return generated
}

function findDocumentsInProject(
  filesObject: IFiles,
  editedDocumentData: string,
  currentDocumentPath: string
) {
  try {
    let documents: DocumentNodeLocation[] = Object.entries(filesObject)
      .filter((filePath) => filePath[0].endsWith(".graphql"))
      .map((data) => {
        const locationPath = data[0]
        const changedFile =
          currentDocumentPath === locationPath ? editedDocumentData : data[1]
        return {
          location: data[0],
          document: parse(changedFile),
        }
      })
    return documents
  } catch (e) {
    console.log(e)
    transpileErrorAction(e)
  }
}

export function findGraphqlConfig({filesObject, workbench}:ConfigObject) {
  const codegen = new Codegen(filesObject)
  return codegen.loadGraphqlCodegenConfig(workbench)
}
