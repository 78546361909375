import FlexSearch, { Index } from "flexsearch"
import { URI } from "vscode-uri"

import fetchProjectZip from "../fetch-project/fetchProjectZip"
import { messagingService, workbench } from "../App"
import {
  bundleFilesAction,
  projectLoadedAction,
  transpileSingleFileAction,
} from "../messaging/actions"
import {
  BUNDLE_FILES,
  FILE_WRITE,
  FRONTEND_FILE_WRITE,
  GENERATE_GRAPHQL,
  GET_PROJECT_ZIP,
} from "../messaging/messageTypes"
import { IFiles } from "../types"
import { projectUrls, ProjectUrlKeys } from "../util/projectUrls"
import { BundleFilesData } from "../bundler"
import { Directory, File } from "../vscode/vfs"
import { VSCodeFSTransferableError, VsCodeFSErrors } from "../vscode/errors"
import { FileStat, FileType, Entry } from "../vscode/vfs"
import { CodeDir, CodeRW } from "@iteria-app/react-lowcode/esm/io"
import {
  dispatchFrontendFileWriteAction,
  notifyVSCodeFrontend,
  transformObjectToUint8Array,
  uint8ToString,
} from "./func"
import { CONFIG_PATH } from "../util/constants"
import {
  graphqlCodegen,
  parseFileMapToFileObject,
  findGraphqlConfig,
} from "../graphql/generateGraphqlTypesFile"
import { stripExtension } from "../codeHandlers/strip"
import { ConfigProps } from "../graphql/types"

export interface FileWriteData {
  path: string
  data: string
}

export interface SearchFile {
  uri: URI
  data: string
}

export interface ProjectLoadedData {
  files: IFiles
  activeFilePath: string
}

export type ChangeActiveFilePathData = Pick<FileWriteData, "path">
export type GetProjectZipData = { repo: ProjectUrlKeys }

export class Workbench implements CodeRW, CodeDir {
  constructor(files: IFiles | undefined) {
    if (files) {
      this.root = this._transformObjectToFsTree(files)
    }
    this.fileCount = 0
    this.searchIndex = FlexSearch.create()
    this.fileMap = new Map<number, SearchFile>()
    this.transpiledFiles = null

    messagingService.addEventListener<FileWriteData>(
      FILE_WRITE,
      ({ data, path }) => this.writeFile(path, data)
    )

    messagingService.addEventListener(GENERATE_GRAPHQL, async () => {
      const filesArray = Array.from(this.fileMap.values())
      const filesObject = await parseFileMapToFileObject(filesArray)
      await graphqlCodegen({ workbench, filesObject })
    })

    messagingService.addEventListener<FileWriteData>(
      FRONTEND_FILE_WRITE,
      async ({ data, path }) => {
        if (!this.transpiledFiles) return

        if (path.endsWith(".graphql")) {
          const filesArray = Array.from(this.fileMap.values())
          const filesObject = await parseFileMapToFileObject(filesArray)
          const generated = await graphqlCodegen({
            workbench,
            filesObject,
            data,
            path,
          })
          notifyVSCodeFrontend(path, generated)
        } else {
          transpileSingleFileAction(path, data, this.transpiledFiles)
        }
      }
    )

    messagingService.addEventListener<GetProjectZipData>(
      GET_PROJECT_ZIP,
      async ({ repo }) => {
        const project = projectUrls[repo]
        const files = await fetchProjectZip(project.vercelUrl)
        const activeFilePath = project.editorEntryPoint

        if (files[CONFIG_PATH]) {
          const filesObject = files
          const generatedFile = await graphqlCodegen({
            workbench,
            filesObject,
          })

          const config = await findGraphqlConfig({ filesObject, workbench })
          const { generates } = config as ConfigProps
          const genFiles = Object.keys(generates ?? {})
          const generatedFilePath = genFiles?.length > 0 ? genFiles[0] : ""
          files[`/${generatedFilePath}`] = generatedFile as string
        }

        this.root = this._transformObjectToFsTree(files)
        projectLoadedAction(files, activeFilePath)
      }
    )

    messagingService.addEventListener<BundleFilesData>(
      BUNDLE_FILES,
      (transpiledFiles) => (this.transpiledFiles = transpiledFiles.files)
    )
  }

  private transpiledFiles: IFiles | null
  private root: Directory | undefined
  private searchIndex: Index<string>
  private fileCount: number
  private fileMap: Map<number, SearchFile>

  private _transformObjectToFsTree(files: IFiles) {
    const root = new Directory(URI.parse(""))

    for (const path in files) {
      const pathParts = path.split("/").filter(Boolean)
      let currentDir = root

      pathParts.forEach((p, i) => {
        if (i === pathParts.length - 1) {
          if (files[path] === null) {
            // It is empty folder
            currentDir.entries.set(p, new Directory(URI.parse(path)))
          } else {
            const fileContent = files[path]
            const fileId = this._getUniqueFileId()
            const fileUri = URI.parse(path)
            currentDir.entries.set(
              p,
              new File(fileUri, Buffer.from(fileContent), fileId)
            )
            // Add file to searchIndex
            this.searchIndex.add(fileId, fileContent)
            this.fileMap.set(fileId, { data: fileContent, uri: fileUri })
          }
        }

        if (!currentDir.entries.has(p)) {
          const dir = pathParts.slice(0, i + 1)
          const dirString = dir.join("/")
          currentDir.entries.set(p, new Directory(URI.parse(dirString)))
        }
        currentDir = currentDir.entries.get(p) as Directory
      })
    }
    return root
  }

  private _getUniqueFileId() {
    this.fileCount++
    return this.fileCount
  }

  private _updateFileInSearchIndex(file: File, content: Uint8Array) {
    const fileData = uint8ToString(content)
    this.searchIndex.update(file.id, fileData)
    this.fileMap.set(file.id, { data: fileData, uri: file.uri })
  }

  private _renameInTranspiledFiles(oldUri: URI, newUri: URI) {
    const oldPath = stripExtension(oldUri.path)
    const newPath = stripExtension(newUri.path)

    delete Object.assign(this.transpiledFiles, {
      [newPath]: this.transpiledFiles![oldPath],
    })[oldPath]

    if (this.transpiledFiles) bundleFilesAction(this.transpiledFiles)
  }

  private _deleteInTranspiledFiles(uri: URI) {
    const path = stripExtension(uri.path)
    delete this.transpiledFiles![path]
    if (this.transpiledFiles) bundleFilesAction(this.transpiledFiles)
  }

  getRoot() {
    return this.root
  }

  getFileMapEntry(id: number) {
    return this.fileMap.get(id)
  }

  getSearchIndex() {
    return this.searchIndex
  }

  getTranspiledFiles() {
    return this.transpiledFiles
  }

  async readFile(path: string, encoding?: string | undefined) {
    const data = this.readFileTree(URI.parse(path))
    if (data instanceof Uint8Array) {
      return uint8ToString(data)
    }
  }

  async writeFile(path: string, data: string) {
    const uri = URI.parse(path)
    const uintData = Buffer.from(data)
    const content = this.writeFileTree(uri, uintData, {
      overwrite: true,
      create: true,
    })
    if (!(content instanceof VSCodeFSTransferableError)) {
      dispatchFrontendFileWriteAction(uri, uintData)
    }

    notifyVSCodeFrontend(path, data)
  }

  // Replacement for path.posix.basename
  private _resolveFilename(uri: URI) {
    const pathParts = uri.path.split("/").filter(Boolean)
    const basename = pathParts[pathParts.length - 1]
    return basename
  }

  // Replacement for path.posix.dirname ??
  private _resolveDirname(uri: URI) {
    const pathParts = uri.path.split("/").filter(Boolean)
    pathParts.pop()

    if (!pathParts.length) {
      return "/"
    }
    return pathParts.join("/")
  }

  stat(uri: URI): FileStat | VSCodeFSTransferableError {
    return this._lookup(uri, false)
  }
  //todo
  async readDirectory(
    path: string,
    extensions?: readonly string[] | undefined,
    exclude?: readonly string[] | undefined,
    include?: readonly string[] | undefined,
    depth?: number | undefined
  ): Promise<any> {
    const data = this.readDirectoryTree(URI.parse(path))
    return data
  }

  readDirectoryTree(
    uri: URI
  ): [string, FileType][] | VSCodeFSTransferableError {
    const entry = this._lookupAsDirectory(uri, false)
    if (entry instanceof VSCodeFSTransferableError) {
      return entry
    }

    const result: [string, FileType][] = []
    for (const [name, child] of entry.entries) {
      result.push([name, child.type])
    }
    return result
  }

  readFileTree(uri: URI): Uint8Array | VSCodeFSTransferableError {
    const data = this._lookupAsFile(uri, false)
    if (data instanceof VSCodeFSTransferableError) {
      return data
    }
    return data.data
  }

  writeFileTree(
    uri: URI,
    content: Uint8Array,
    options: { create: boolean; overwrite: boolean }
  ): Uint8Array | VSCodeFSTransferableError {
    const basename = this._resolveFilename(uri)
    const parent = this._lookupParentDirectory(uri)
    const normalizedContent = transformObjectToUint8Array(content)

    if (parent instanceof VSCodeFSTransferableError) {
      return parent
    }
    let entry = parent.entries.get(basename)

    if (entry instanceof Directory) {
      return new VSCodeFSTransferableError(VsCodeFSErrors.FileIsADirectory, uri)
    }

    if (!entry && !options.create) {
      return new VSCodeFSTransferableError(VsCodeFSErrors.FileNotFound, uri)
    }
    if (entry && options.create && !options.overwrite) {
      return new VSCodeFSTransferableError(VsCodeFSErrors.FileExists, uri)
    }

    if (!entry) {
      const fileId = this._getUniqueFileId()
      entry = new File(URI.parse(basename), normalizedContent, fileId)
      parent.entries.set(basename, entry)
    }
    entry.mtime = Date.now()
    entry.size = normalizedContent.byteLength
    entry.data = normalizedContent
    this._updateFileInSearchIndex(entry, normalizedContent)

    return normalizedContent
  }

  rename(
    oldUri: URI,
    newUri: URI,
    options: { overwrite: boolean }
  ): VSCodeFSTransferableError | void {
    if (!options.overwrite && this._lookup(newUri, true)) {
      return new VSCodeFSTransferableError(VsCodeFSErrors.FileExists, newUri)
    }

    const entry = this._lookup(oldUri, false)
    const oldParent = this._lookupParentDirectory(oldUri)

    const newParent = this._lookupParentDirectory(newUri)
    const newName = this._resolveFilename(newUri)

    if (oldParent instanceof VSCodeFSTransferableError) {
      return oldParent
    }
    if (newParent instanceof VSCodeFSTransferableError) {
      return newParent
    }

    oldParent.entries.delete(entry.name)
    entry.name = newName
    newParent.entries.set(newName, entry)

    this._renameInTranspiledFiles(oldUri, newUri)
  }

  delete(uri: URI): VSCodeFSTransferableError | void {
    const dirname = URI.parse(this._resolveDirname(uri))
    const basename = this._resolveFilename(uri)
    const parent = this._lookupAsDirectory(dirname, false)
    if (parent instanceof VSCodeFSTransferableError) {
      return parent
    }
    if (!parent.entries.has(basename)) {
      return new VSCodeFSTransferableError(VsCodeFSErrors.FileNotFound, uri)
    }
    parent.entries.delete(basename)
    parent.mtime = Date.now()
    parent.size -= 1

    this._deleteInTranspiledFiles(uri)
  }

  createDirectory(uri: URI): VSCodeFSTransferableError | void {
    const dirname = URI.parse(this._resolveDirname(uri))
    const basename = this._resolveFilename(uri)
    const parent = this._lookupAsDirectory(dirname, false)
    if (parent instanceof VSCodeFSTransferableError) {
      return parent
    }

    const entry = new Directory(URI.parse(basename))
    parent.entries.set(entry.name, entry)
    parent.mtime = Date.now()
    parent.size += 1
  }

  private _lookup(uri: URI, silent: false): Entry
  private _lookup(
    uri: URI,
    silent: boolean
  ): Entry | undefined | VSCodeFSTransferableError
  private _lookup(
    uri: URI,
    silent: boolean
  ): Entry | undefined | VSCodeFSTransferableError {
    const parts = uri.path.split("/")
    let entry: Entry = this.root ?? new Directory(URI.parse(""))
    for (const part of parts) {
      if (!part) {
        continue
      }
      let child: Entry | undefined
      if (entry instanceof Directory) {
        child = entry.entries.get(part)
      }
      if (!child) {
        if (!silent) {
          return new VSCodeFSTransferableError(VsCodeFSErrors.FileNotFound, uri)
        } else {
          return undefined
        }
      }
      entry = child
    }

    return entry
  }

  private _lookupAsDirectory(
    uri: URI,
    silent: boolean
  ): Directory | VSCodeFSTransferableError {
    const entry = this._lookup(uri, silent)
    if (entry instanceof Directory) {
      return entry
    }
    return new VSCodeFSTransferableError(VsCodeFSErrors.FileNotADirectory, uri)
  }

  private _lookupAsFile(
    uri: URI,
    silent: boolean
  ): File | VSCodeFSTransferableError {
    const entry = this._lookup(uri, silent)
    if (entry instanceof File) {
      return entry
    }
    return new VSCodeFSTransferableError(VsCodeFSErrors.FileIsADirectory, uri)
  }

  private _lookupParentDirectory(
    uri: URI
  ): Directory | VSCodeFSTransferableError {
    const dirUri = this._resolveDirname(uri)
    const dirname = URI.parse(dirUri)
    return this._lookupAsDirectory(dirname, false)
  }
}
