import {
  SourceLineCol,
  startOfJsxIdentifier,
} from "@iteria-app/react-lowcode/esm/ast/find"
import {
  CLONE_ELEMENT,
  ElementHighlightMessageType,
  NAVIGATE_TO_SOURCE_CODE,
  REMOVE_ELEMENT,
} from "../messaging/messageTypes"
import { getDataFromElement, getSourceFromElement } from "../devtools/source"
import { elementHighlightClickAction, fileWriteAction } from "../messaging/actions"
import { workbench } from "../App"
import { editFormattedMessage } from "./inlineEdit"
import { getColIndex, getFormInputIndex, isColumnTitle } from "../devtools/table"
import { getFormWidgetProperties, setFormWidgetProperties } from "@iteria-app/react-lowcode/esm/codegen"
import { fetchGraphqlIntrospectionSchema, getURLFromConfig } from "../graphql/generateGraphqlTypesFile"
import { findTypenameFromTable } from "../devtools/typename"

export const addElementHighlight = (contentWindow: Window) => {
  const el = document.createElement("iteria-wysiwyg")
  let hoveredElement: HTMLElement

  // @ts-ignore
  el.win = contentWindow
  el.addEventListener("hover", async (e: any) => {
    const {
      detail: { inspect },
    } = e
    hoveredElement = e.detail.hoverElement
    const tooltipText = await getComponentName(contentWindow, hoveredElement)

    inspect({
      display: true,
      tooltipText,
      disabled: false,
      icon: isFormattedMessage(hoveredElement) ? "edit" : "settings",
    })
  })

  el.addEventListener("delete", (e: any) => {
    const editingElement = e?.detail?.editingElement
    console.log("delete event", e, "editingElement:", editingElement)
    if (isColumnTitle(editingElement) && isDataGridCell(editingElement)) {
      handleRemoveTableColumn(contentWindow, editingElement, REMOVE_ELEMENT)
    } else {
      handleIconClick(contentWindow, editingElement, REMOVE_ELEMENT)
    }
  })
  
  el.addEventListener("clone", async (e: any) => {
    const editingElement = e?.detail?.editingElement
    if (isDataGridCell(editingElement) && isColumnTitle(editingElement)) {
      handleCloneTableColumn(contentWindow, editingElement, CLONE_ELEMENT, e)
    } else if (isFormInput(editingElement)) {
      handleCloneFormInput(contentWindow, editingElement, CLONE_ELEMENT, e)
    } else {
      handleIconClick(contentWindow, editingElement, CLONE_ELEMENT)
      if (typeof e?.detail?.hide == 'function') e?.detail?.hide()
    }
  })

  el.addEventListener("showsource", (e: any) => {
    const editingElement = e?.detail?.editingElement
    console.log("showsource event", e, "editingElement:", editingElement)

    handleIconClick(contentWindow, editingElement, NAVIGATE_TO_SOURCE_CODE)
  })

  el.addEventListener("editing", async (e: any) => {
    const editingElement = e?.detail?.editingElement
    console.log("editing event", e, "editingElement:", editingElement)
    
    if (isFormattedMessage(editingElement)) {
      const inspectedElement = getDataFromElement(
        contentWindow.__REACT_DEVTOOLS_GLOBAL_HOOK__!,
        editingElement
      )
      if (inspectedElement){
        if (inspectedElement.displayName === "Anonymous") {
          inspectedElement.displayName = "FormattedMessage"
        }
        editFormattedMessage(editingElement, inspectedElement, workbench)
      }
      // need rework after lowcode and wysiwyg rework 
    } else if (isFormInput(editingElement)) {
      console.log(e?.detail)
      const dialog = e?.detail?.dialogWidgetFields
      const hide = e?.detail?.hide
      
      const source = getSourceFromElement(
        contentWindow.__REACT_DEVTOOLS_GLOBAL_HOOK__,
        editingElement
      )

      const props = await getFormWidgetProperties(workbench, source)
      const { fileName } = source
      const file = await workbench.readFile(fileName)
      if (!file) throw new Error("File not found in Workbench")
      await dialog({
        fields: props.properties,
        fieldsChanged: async (e: any) => {
          const data = await setFormWidgetProperties(workbench, source, props)
          if (data) {
            if (typeof hide === "function") hide()
            fileWriteAction(fileName, data)
          }
        }
      })
    }
  })
  document.body.appendChild(el)
}

const getComponentName = async (contentWindow: Window, el: HTMLElement) => {
  const source = getSourceFromElement(
    contentWindow.__REACT_DEVTOOLS_GLOBAL_HOOK__,
    el
  )
  if (!source) return console.warn("Source not found")
  const fileContent = await workbench.readFile(source.fileName)
  if (!fileContent) return console.warn("File not found")
  const tooltipText = getParsedTagRegxp(fileContent, source)
  return tooltipText
}

/*Parse tag from file at speciffic position, using Regexp, faster but less accurate version without using AST*/
const getParsedTagRegxp = (fileContent: string, source: SourceLineCol) => {
  const startOfJsxTag = startOfJsxIdentifier(fileContent, source)
  if (startOfJsxTag && fileContent.charAt(startOfJsxTag - 1) === "<") {
    return fileContent?.substring(startOfJsxTag)?.match(/\w+/)?.pop()
  }
}

export const handleCloneTableColumn = async (
  contentWindow: Window,
  el: HTMLElement,
  type: ElementHighlightMessageType,
  event : CustomEvent,
) => {
  const devtoolsHook = contentWindow.__REACT_DEVTOOLS_GLOBAL_HOOK__

  if (!devtoolsHook)
    throw new Error(
      "__REACT_DEVTOOLS_GLOBAL_HOOK__ not available on window object"
    )
  const inspectedElement = getDataFromElement(
    contentWindow.__REACT_DEVTOOLS_GLOBAL_HOOK__!,
    el
    )
  const inspectedElOwners = inspectedElement?.owners
  const typename = await findTypenameFromTable(inspectedElOwners, devtoolsHook) 
  const source = getSourceOfTable(el, contentWindow)
  if (!source) throw new Error("Source not found")
  const dialog = event.detail.dialogInsertField
  const hide = event.detail.hide
  
  const URLSchema = await getURLFromConfig(workbench)
  const schema = await fetchGraphqlIntrospectionSchema(
    URLSchema
  )
  // test edge cases open, close, exit, double click
  await dialog({
    intro: schema,
    fieldSelected: async (e: any) => {
      const columnIndex = getColIndex(el)
      source.columnToAdd = e.selectedFields.name
      source.typename = typename
      if (columnIndex) {
        if (e.beforeAfter === "before")
          source.colIndex = columnIndex
        else 
          source.colIndex = columnIndex + 1
        elementHighlightClickAction(type, source)
        if (typeof hide === "function") hide()
      }
    }
  })
}

export const handleCloneFormInput = async (
  contentWindow: Window,
  el: HTMLElement,
  type: ElementHighlightMessageType,
  event: CustomEvent
) => {
  if (!contentWindow.__REACT_DEVTOOLS_GLOBAL_HOOK__)
    throw new Error(
      "__REACT_DEVTOOLS_GLOBAL_HOOK__ not available on window object"
    )
  const source = getSourceFromElement(
    contentWindow.__REACT_DEVTOOLS_GLOBAL_HOOK__,
    el
  )

  const dialog = event.detail.dialogInsertField
  const hide = event.detail.hide
  
  const URLSchema = await getURLFromConfig(workbench)
  const schema = await fetchGraphqlIntrospectionSchema(
    URLSchema
  )
  await dialog({
    intro: schema,
    fieldSelected: async (e: any) => {
      const columnIndex = getFormInputIndex(el)
      source.columnToAdd = e.selectedFields.name
      if (columnIndex) {
        if (e.beforeAfter === "before")
          source.colIndex = columnIndex
        else 
          source.colIndex = columnIndex + 1
        console.log(source)
        elementHighlightClickAction(type, source)
        if (typeof hide === "function") hide()
      }
    }
  })
}

export const handleRemoveTableColumn = (
  contentWindow: Window,
  el: HTMLElement,
  type: ElementHighlightMessageType
) => {
  if (!contentWindow.__REACT_DEVTOOLS_GLOBAL_HOOK__)
    throw new Error(
      "__REACT_DEVTOOLS_GLOBAL_HOOK__ not available on window object"
    )
  const source = getSourceOfTable(el, contentWindow)
  source.colIndex = getColIndex(el)
  if (!source) throw new Error("Source not found")
  elementHighlightClickAction(type, source)
}



export const handleIconClick = (
  contentWindow: Window,
  el: HTMLElement,
  type: ElementHighlightMessageType
) => {
  console.log("handleIconClick", el, contentWindow, type)

  if (!contentWindow.__REACT_DEVTOOLS_GLOBAL_HOOK__)
    throw new Error(
      "__REACT_DEVTOOLS_GLOBAL_HOOK__ not available on window object"
    )

  const source = getSourceFromElement(
    contentWindow.__REACT_DEVTOOLS_GLOBAL_HOOK__,
    el
  )

  if (!source) throw new Error("Source not found")
  elementHighlightClickAction(type, source)
}

const getSourceOfTable = (el: HTMLElement, contentWindow: Window) => {
  let currElement = el

  while (currElement.parentElement) {
    if (currElement.classList.contains("MuiDataGrid-root")) {
      return getSourceFromElement(
        contentWindow.__REACT_DEVTOOLS_GLOBAL_HOOK__,
        currElement
      )
    }
    currElement = currElement.parentElement
  }
}

const isDataGridCell = (el: HTMLElement) =>
  el.classList.contains("MuiDataGrid-cell") || isColumnTitle(el)

const isFormInput = (el: HTMLElement) => 
  el.classList.contains("MuiTextField-root") || el.classList.contains("MuiInput-input") || el.classList.contains("MuiInputBase-input")

const isDataGridColHead = (el: HTMLElement) =>
  domTokenListStartsWith(el as any, "MuiDataGrid-colCell")

const domTokenListStartsWith = (el: DOMTokenList, prefix: string) => {
  for (let i = 0; i < el.length; i++) {
    const className = el[i]
    if (className.startsWith(prefix)) {
      return true
    }
  }
  return false
}

const isFormattedMessage = (el: HTMLElement) =>
  el.tagName == "SPAN" || isColumnTitle(el)
