import { IFiles } from "../types"
import { CONFIG_PATH } from "../util/constants"
import { load } from "js-yaml"
import { CodeRW } from "@iteria-app/react-lowcode/esm/io"

export class Codegen {
  constructor(files?: IFiles | undefined) {
    this.filesArray = files ? files :(null as any) as IFiles
  }

  private filesArray: IFiles

  async loadGraphqlCodegenConfig(io: CodeRW){
    let config
    if(this.filesArray && this.filesArray[CONFIG_PATH]){
      config = this.filesArray[CONFIG_PATH] ?? ""
    }else {
      config = (await io.readFile(CONFIG_PATH)) ?? ""
    }
    return load(config) ?? ""
  }
}