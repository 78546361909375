import { IFRAME_SRC_LOADING } from "../util/constants"
import { messagingService } from "../App"
import {
  PERFORM_REACT_REFRESH,
  REFRESH_IFRAME,
} from "../messaging/messageTypes"
import { windowHasDeps, windowHasNoDeps } from "./windowDeps"
import {
  connectDevtoolsAction,
  connectElementHighlightAction,
  iframeRefreshedAction,
  refreshIframeAction,
} from "../messaging/actions"

export interface RefreshIframeData {
  bundle: string
}
export type PerformReactRefreshData = RefreshIframeData

export default class IFrame {
  constructor(iframe: HTMLIFrameElement) {
    this.iframe = iframe
    this.vercelHtml = undefined

    messagingService.addEventListener<RefreshIframeData>(
      REFRESH_IFRAME,
      async ({ bundle }) => {
        if (!this.iframe) this.setIframe()

        const { contentWindow, contentDocument } = this.iframe

        if (!contentWindow || !contentDocument) {
          throw new Error(
            "Unable to destructure contentWindow/contentDocument from iframe"
          )
        }
        this.iframe.src = IFRAME_SRC_LOADING

        await windowHasNoDeps(contentWindow)
        // @ts-ignore
        contentWindow.$RefreshSig$ = () => type => type
        // @ts-ignore
        contentWindow.$RefreshRegGlobal$ = window.reactRefresh.register

        this.appendReactRefresh(contentWindow)

        connectDevtoolsAction()
        this.getVercelBundle()
        await windowHasDeps(contentWindow)
        contentWindow.__skip_render = false

        const script = contentDocument.createElement("script")
        script.innerHTML = bundle
        this.iframe.contentDocument?.body.appendChild(script)
        connectElementHighlightAction()
        iframeRefreshedAction()
      }
    )

    messagingService.addEventListener<PerformReactRefreshData>(
      PERFORM_REACT_REFRESH,
      ({ bundle }) => {
        const iframe = this.iframe
        //@ts-ignore
        if (!iframe.contentWindow.$RefreshRuntime$) {
          refreshIframeAction(bundle)
          return
        }
        const s = document.createElement("script")
        s.text = bundle
        iframe.contentDocument?.body.appendChild(s)

        // @ts-ignore
        const something = iframe.contentWindow.$RefreshRuntime$.performReactRefresh()
        iframeRefreshedAction()
      }
    )
  }
  private iframe: HTMLIFrameElement
  private vercelHtml: string | undefined

  appendReactRefresh(contentWindow: Window) {
    window.addEventListener("message", e => {
      if (e.data.type === "React::DevTools::getSavedPreferences") {
        // React refresh runtime is loaded from CDN and appended to the window in index.html
        //@ts-ignore
        const reactRefreshRuntime = window.reactRefresh
        // @ts-ignore

        //@ts-ignore
        contentWindow.$RefreshRuntime$ = reactRefreshRuntime
        //@ts-ignore
        reactRefreshRuntime.injectIntoGlobalHook(contentWindow)
      }
    })
  }

  getVercelBundle() {
    const { contentWindow } = this.iframe

    if (!contentWindow)
      throw new Error("Unable to destructure contentWindow from iframe")
    if (!this.vercelHtml) throw new Error("VercelHtml is null")

    contentWindow.document.write(this.vercelHtml)
  }

  async setVercelHtml(vercelUrl: string) {
    if (!this.iframe) this.setIframe()
    const { contentWindow } = this.iframe
    if (!contentWindow) {
      throw new Error("Unable to destructure contentWindow from iframe")
    }

    if (this.vercelHtml) {
      contentWindow.document.write(this.vercelHtml)
    } else {
      try {
        const res = await fetch(vercelUrl)
        const data = await res.text()
        const finalString = this.alterVercelString(data, vercelUrl)
        this.vercelHtml = finalString
      } catch (err) {
        throw new Error(err)
      }
    }
  }

  private alterVercelString(vercelString: string, vercelUrl: string) {
    const startOfScript = vercelString.indexOf("<script")
    const firstHalf = vercelString.substring(0, startOfScript)
    const secondHalf = vercelString.substring(startOfScript)

    let finalString =
      firstHalf +
      `<script>
        window.__skip_render = true
        </script>
       ` +
      secondHalf
    finalString = finalString.replaceAll(
      /(href|src)\s*=\s*"(?!http)(.+?)"/g,
      `src="${vercelUrl}$2"`
    )

    return finalString
  }

  setIframe() {
    const iframe = document.getElementById("iframe") as HTMLIFrameElement
    this.iframe = iframe
  }

  getIframe() {
    return this.iframe
  }
}
