import { useState } from "react"
import { Route, BrowserRouter as Router } from "react-router-dom"
import { Project } from "ts-morph"

import { MainContext, MainContextObject } from "./context/context"
import { SplitPaneContext, SplitPaneContextObject } from "./context/context"
import { Workbench } from "./workbench/Workbench"
import { BundleWorker } from "./bundler"
import IFrame from "./iframe"
import WindowMessaging from "./messaging"
import WorkbenchComponent from "./WorkbenchComponent"
import { TOOLBAR_OPTIONS } from "./util/constants"

export const messagingService = new WindowMessaging(window)
const tsMorphProject = new Project()
const bundler = new BundleWorker(tsMorphProject)
export const workbench = new Workbench(undefined)
const frame = document.getElementById("iframe") as HTMLIFrameElement
const iframe = new IFrame(frame)

const App = () => {
  const [mainContext, setMainContext] = useState<MainContextObject>({
    bundler,
    tsMorphProject,
    workbench,
    iframe,
    messagingService,
  })

  const [
    splitPaneContext,
    setSplitPaneContext,
  ] = useState<SplitPaneContextObject>({
    selected: TOOLBAR_OPTIONS.VSCODE,
    isVertical: false,
    display: true,
  })

  return (
    <MainContext.Provider value={{ mainContext, setMainContext }}>
      <SplitPaneContext.Provider
        value={{ splitPaneContext, setSplitPaneContext }}
      >
        <Router>
          <Route exact path="/" component={WorkbenchComponent} />
        </Router>
      </SplitPaneContext.Provider>
    </MainContext.Provider>
  )
}
export default App
