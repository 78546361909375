export const IFRAME_SRC_LOADING = "/loading.html"
export const JS_EXTENSIONS = [".js", ".jsx", ".ts", ".tsx"]

export enum TOOLBAR_OPTIONS {
  DEV = "devtools",
  VSCODE = "vscode",
  GRAPHQL = "graphql",
  DATABASE = "database",
  ANALYSE = "analyse",
  ROOT = "root",
}

export const IFRAME_VSCODE_SRC = "https://vscode-web.iteria.app/"
export const VSCODE_IFRAME_ID = "vscode-iframe"

export const CONFIG_PATH = "/codegen.yml"