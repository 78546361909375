import JSZip from "jszip"
import { IFiles } from "../types"

const fetchProjectZip = async (repoUrl: string) => {
  try {
    const res = await fetch(`${repoUrl}/Sources.zip`)
    const blob = await res.blob()
    const jsZip = await JSZip.loadAsync(blob)

    const fileObject: IFiles = {}
    const promiseArray: Array<Promise<void>> = []

    jsZip.forEach((path, file) => {
      const promise = file.async("text").then(fileContent => {
        if (fileContent) {
          fileObject[`/${path}`] = fileContent
        }
      })
      promiseArray.push(promise)
    })
    await Promise.all(promiseArray)
    return fileObject
  } catch (err) {
    throw new Error(err)
  }
}

export default fetchProjectZip
