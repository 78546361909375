import {codegen} from '@graphql-codegen/core'
import {parse} from 'graphql'
import hrtime  from 'browser-process-hrtime'
import { transpileErrorAction } from '../messaging/actions'
import { DocumentNodeLocation } from './types'

interface GenerateProps {
  config: string | number | object,
  generatedSchema: string,
  documents: DocumentNodeLocation[]|undefined,
}
interface ConfigI {
  generates : [string,any][any]
}
interface HRTime {
  (time?: [number, number]): [number, number];
  bigint(): bigint;
}

function setupConfig ( generates : ConfigI ) {
  let options = []
  for (const [filename, outputOptions]  of Object.entries(generates) as [string,any][any]) {
    options.push ({
      filename: filename,
      config: outputOptions?.config
    })
  }
  return options[0]
}
export async function generateTypes({config, generatedSchema, documents }: GenerateProps ) {
  process.hrtime = hrtime as HRTime 
  const typescriptPlugin = await import('@graphql-codegen/typescript')
  const operationsPlugin = await import('@graphql-codegen/typescript-operations')
  const urqlPlugin = await import('@graphql-codegen/typescript-urql')

  try {
    const { generates } = config as ConfigI

    const runConfigurations = []
    var output = ""

    const options = setupConfig(generates)
    const plugins = [
      {typescript:{}},
      {'typescript-operations':{}},
      {'typescript-urql': {}},
    ]

    const pluginMap = {
      typescript : typescriptPlugin,
      'typescript-operations' : operationsPlugin,
      'typescript-urql': urqlPlugin,
    }

    if (generatedSchema !== ""){
      runConfigurations.push({
        filename: options?.filename,
        plugins,
        schema:  parse(generatedSchema),
        documents: documents,
        config: options?.config,
        pluginMap,
      });
      output = await codegen(runConfigurations[0] as any)
    }
    return output
  } catch (e) {
    // tslint:disable-next-line: no-console
    console.error(e.message)
    transpileErrorAction(e)
  }
}
