import { ts } from "@ts-morph/common"
import { formatCode } from "../format/prettier"

export const encodeEmptyLines = (code: string) =>
  code.replace(/\n\n/g, "\n/** THIS_IS_A_NEWLINE **/\n")

export const decodeEmptyLines = (code: string) =>
  code.replace(/\/\*\* THIS_IS_A_NEWLINE \*\*\//g, "")

export const printFormattedSourceFile = (sourceFile: ts.SourceFile) => {
  const printer = ts.createPrinter()
  const code = printer.printFile(sourceFile)
  const decoded = decodeEmptyLines(code)
  const formattedCode = formatCode(decoded)
  return formattedCode
}
