export const CLONE_ELEMENT = "CLONE_ELEMENT"
export const NAVIGATE_TO_SOURCE_CODE = "NAVIGATE_TO_SOURCE_CODE"
export const REMOVE_ELEMENT = "REMOVE_ELEMENT"

export const FILE_WRITE = "FILE_WRITE"
export const FRONTEND_FILE_WRITE = "FRONTEND_FILE_WRITE"
export const CHANGE_ACTIVE_FILE_PATH = "CHANGE_ACTIVE_FILE_PATH"
export const GET_PROJECT_ZIP = "GET_PROJECT_ZIP"
export const PROJECT_LOADED = "PROJECT_LOADED"
export const BUNDLE_FILES = "BUNDLE_FILES"
export const TRANSPILE_FILES = "TRANSPILE_FILES"
export const TRANSPILE_SINGLE_FILE = "TRANSPILE_SINGLE_FILE"
export const REFRESH_IFRAME = "REFRESH_IFRAME"
export const PERFORM_REACT_REFRESH = "PERFORM_REACT_REFRESH"
export const IFRAME_REFRESHED = "IFRAME_REFRESHED"
export const CONNECT_DEVTOOLS = "CONNECT_DEVTOOLS"
export const CONNECT_ELEMENT_HIGHLIGHT = "CONNECT_ELEMENT_HIGHLIGHT"
export const GENERATE_GRAPHQL = "GENERATE_GRAPHQL"

export const BUNDLER_ERROR = "BUNDLER_ERROR"
export const TRANSPILER_ERROR = "TRANSPILER_ERROR"

export const SET_REPO = "SET_REPO"

export type MessageType =
  | typeof FILE_WRITE
  | typeof FRONTEND_FILE_WRITE
  | typeof CHANGE_ACTIVE_FILE_PATH
  | typeof GET_PROJECT_ZIP
  | typeof PROJECT_LOADED
  | typeof TRANSPILE_FILES
  | typeof TRANSPILE_SINGLE_FILE
  | typeof BUNDLE_FILES
  | typeof REFRESH_IFRAME
  | typeof PERFORM_REACT_REFRESH
  | typeof IFRAME_REFRESHED
  | typeof CONNECT_DEVTOOLS
  | typeof CONNECT_ELEMENT_HIGHLIGHT
  | typeof BUNDLER_ERROR
  | typeof TRANSPILER_ERROR
  | typeof GENERATE_GRAPHQL
  | ElementHighlightMessageType

export type ElementHighlightMessageType =
  | typeof CLONE_ELEMENT
  | typeof NAVIGATE_TO_SOURCE_CODE
  | typeof REMOVE_ELEMENT
