import { createContext, useContext } from "react"

import { Project } from "ts-morph"
import { BundleWorker } from "../bundler"
import IFrame from "../iframe"
import WindowMessaging from "../messaging"
import { TOOLBAR_OPTIONS } from "../util/constants"
import { Workbench } from "../workbench/Workbench"

export interface MainContextObject {
  tsMorphProject: Project
  bundler: BundleWorker
  workbench: Workbench
  iframe: IFrame
  messagingService: WindowMessaging
}

export interface MainContextType {
  mainContext: MainContextObject
  setMainContext: (mainContext: MainContextObject) => void
}

export const MainContext = createContext<MainContextType>({
  mainContext: {} as MainContextObject,
  setMainContext: () => console.warn(""),
})
export const useMainContext = () => useContext(MainContext)

export interface SplitPaneContextObject {
  selected: TOOLBAR_OPTIONS
  isVertical: boolean
  display: boolean
}
export interface SplitPaneContextType {
  splitPaneContext: SplitPaneContextObject
  setSplitPaneContext: (splitPane: SplitPaneContextObject) => void
}

export const SplitPaneContext = createContext<SplitPaneContextType>({
  splitPaneContext: {} as SplitPaneContextObject,
  setSplitPaneContext: () => console.warn(""),
})

export const useSplitPaneContext = () => useContext(SplitPaneContext)
