import {
  BundleFilesData,
  TranspileFilesData,
  TranspileSingleFileData,
} from "../bundler"
import { PerformReactRefreshData, RefreshIframeData } from "../iframe"
import { messagingService } from "../App"
import {
  FileWriteData,
  ChangeActiveFilePathData,
  GetProjectZipData,
  ProjectLoadedData,
} from "../workbench/Workbench"
import { ErrorData, IFiles } from "../types"
import { ProjectUrlKeys } from "../util/projectUrls"
import {
  FILE_WRITE,
  CHANGE_ACTIVE_FILE_PATH,
  GET_PROJECT_ZIP,
  PROJECT_LOADED,
  BUNDLE_FILES,
  REFRESH_IFRAME,
  CONNECT_DEVTOOLS,
  CONNECT_ELEMENT_HIGHLIGHT,
  ElementHighlightMessageType,
  TRANSPILE_FILES,
  IFRAME_REFRESHED,
  TRANSPILE_SINGLE_FILE,
  TRANSPILER_ERROR,
  PERFORM_REACT_REFRESH,
  FRONTEND_FILE_WRITE,
  GENERATE_GRAPHQL,
} from "./messageTypes"

export const fileWriteAction = (path: string, data: string) =>
  messagingService.postMessage<FileWriteData>(FILE_WRITE, { path, data })

export const frontendFileWriteAction = (path: string, data: string) =>
  messagingService.postMessage<FileWriteData>(FRONTEND_FILE_WRITE, {
    path,
    data,
  })

export const changeActiveFilePathAction = (path: string) =>
  messagingService.postMessage<ChangeActiveFilePathData>(
    CHANGE_ACTIVE_FILE_PATH,
    { path }
  )

export const getProjectZipAction = (repo: ProjectUrlKeys) =>
  messagingService.postMessage<GetProjectZipData>(GET_PROJECT_ZIP, { repo })

export const projectLoadedAction = (files: IFiles, activeFilePath: string) =>
  messagingService.postMessage<ProjectLoadedData>(PROJECT_LOADED, {
    files,
    activeFilePath,
  })

export const transpileFilesAction = (files: IFiles) =>
  messagingService.postMessage<TranspileFilesData>(TRANSPILE_FILES, files)

export const transpileSingleFileAction = (
  path: string,
  data: string,
  files: IFiles
) =>
  messagingService.postMessage<TranspileSingleFileData>(TRANSPILE_SINGLE_FILE, {
    files,
    path,
    data,
  })

export const bundleFilesAction = (files: IFiles, bundleEntry?: string) =>
  messagingService.postMessage<BundleFilesData>(BUNDLE_FILES, {
    files,
    bundleEntry,
  })

export const refreshIframeAction = (bundle: string) =>
  messagingService.postMessage<RefreshIframeData>(REFRESH_IFRAME, {
    bundle,
  })

export const perfromReactRefreshAction = (bundle: string) =>
  messagingService.postMessage<PerformReactRefreshData>(PERFORM_REACT_REFRESH, {
    bundle,
  })

export const iframeRefreshedAction = () =>
  messagingService.postMessage(IFRAME_REFRESHED, {})

export const connectDevtoolsAction = () =>
  messagingService.postMessage(CONNECT_DEVTOOLS, {})

export const connectElementHighlightAction = () =>
  messagingService.postMessage(CONNECT_ELEMENT_HIGHLIGHT, {})

export const elementHighlightClickAction = <SourceLineCol>(
  type: ElementHighlightMessageType,
  source: SourceLineCol
) => messagingService.postMessage(type, source)

export const transpileErrorAction = (err: Error) =>
  messagingService.postMessage<ErrorData>(TRANSPILER_ERROR, { err })

export const generateGraphql = () =>
  messagingService.postMessage(GENERATE_GRAPHQL, {})