import React from "react"
import { ReactComponent as MaterialUiLogo } from "../../icons/materialui.svg"
import { ReactComponent as GrommetLogo } from "../../icons/grommet.svg"
import { ReactComponent as IonicLogo } from "../../icons/ionic.svg"
import "./ProjectPicker.css"

const ProjectPicker: React.FC = () => {
  return (
    <div className="ProjectPicker">
      <a
        className="ProjectPickerLink"
        href="https://demo.iteria.tech/"
      >
        NEW DEMO
      </a>
      <a
        className="ProjectPickerLink"
        href="?repo=github.com/iteria-app/example-material-ui"
      >
        <MaterialUiLogo className="ProjectPickerLogo" />
      </a>
      <a
        className="ProjectPickerLink"
        href="?repo=github.com/iteria-app/example-grommet"
      >
        <GrommetLogo className="ProjectPickerLogo" />
      </a>
      {/* <a
        className="ProjectPickerLink"
        href="?repo=github.com/iteria-app/example-ionic-react"
      >
        <IonicLogo className="ProjectPickerLogo" />
      </a> */}
    </div>
  )
}

export default ProjectPicker
