import { SourceLineCol } from "@iteria-app/react-lowcode/esm/ast/find"
import { removeElementInAst } from "@iteria-app/react-lowcode/esm/remove"

import { encodeEmptyLines, printFormattedSourceFile } from "./encode"

export const removeElement = (code: string, source: SourceLineCol) => {
  const encoded = encodeEmptyLines(code)
  const alteredAst = removeElementInAst(encoded, source)
  if (!alteredAst) return console.error("Unable to remove Element from AST")
  const newCode = printFormattedSourceFile(alteredAst)
  return newCode
}
