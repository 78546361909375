import { DevToolsHook } from "@iteria-app/react-lowcode/esm/devtools/"
import { isColumnTitle } from "./table"

export const getSourceFromElement = (
  devtoolsHook: DevToolsHook | undefined | null,
  el: HTMLElement
) => {
  if (!devtoolsHook) return console.warn("No DevtoolsHook passed")
  const data = getDataFromElement(devtoolsHook, el)
  return data?.source
}

export const getDataFromElement = (
  devtoolsHook: DevToolsHook,
  el: HTMLElement
) => {
  const id = devtoolsHook?.reactDevtoolsAgent?.getIDForNode(el)

  if (!id) return null

  const rendererInterface = (devtoolsHook?.reactDevtoolsAgent as any)
    ._rendererInterfaces["1"]

  if (isColumnTitle(el)) {
    const data = getDataFromChild(id, rendererInterface)
    return data
  }

  const data = getDataFromParent(id, rendererInterface)
  return data
}

const getDataFromParent = (id: number, rendererInterface: any) => {
  for (let i = id; i > id - 100; i--) {
    const path = rendererInterface.getPathForElement(i)

    const data = rendererInterface.inspectElement(i, path)

    if (data.value?.source) return data.value as any
  }
  // throw new Error("Element source not found")
}
const getDataFromChild = (id: number, rendererInterface: any) => {
  for (let i = id; i < id + 100; i++) {
    const path = rendererInterface.getPathForElement(i)

    const data = rendererInterface.inspectElement(i, path)

    if (data.value?.source) return data.value as any
  }
  // throw new Error("Element source not found")
}
