//@ts-ignore
import * as babel from "@babel/core"
import { Project } from "ts-morph"
import { transpileErrorAction } from "../messaging/actions"
import { changeImports } from "../ts-morph/ast"
import { IFiles } from "../types"
import { JS_EXTENSIONS } from "../util/constants"
import { stripExtension } from "./strip"

export const transpileBabelAsync = (code: string, filename: string) =>
  babel.transformAsync(code, {
    filename,
    presets: [
      [require("@babel/preset-react")],
      [require("@babel/preset-typescript")],
      [
        require("@babel/preset-env"),
        {
          targets: {
            esmodules: true,
          },
          modules: false,
        },
      ],
    ],
    plugins: [
      [require("@babel/plugin-transform-react-jsx-source")],
      [require("babel-plugin-styled-components")],
      [require("@babel/plugin-proposal-class-properties")],
      [require("react-refresh/babel"), { skipEnvCheck: true }],
    ],
  })

export const transpileFiles = async (files: IFiles, project: Project) => {
  const mappedFiles: IFiles = {}
  const promiseArray = []

  for (const filePath in files) {
    if (JS_EXTENSIONS.some(e => filePath.endsWith(e))) {
      const newPath = stripExtension(filePath)

      const transpiledPromise = transpileBabelAsync(files[filePath], filePath)
        .then(data => {
          if (data?.code) {
            const importsCode = changeImports(
              project.createSourceFile(filePath, data.code, {
                overwrite: true,
              }),
              filePath
            )
            const finalCode = addCustomRefreshReg(importsCode, filePath)
            mappedFiles[newPath] = finalCode
          }
        })
        .catch(err => {
          console.error(err)
        })
      promiseArray.push(transpiledPromise)
    } else if (filePath.endsWith(".json")) {
      mappedFiles[filePath] = "export default" + files[filePath]
    }
  }
  await Promise.all(promiseArray).catch(err => {
    console.error(err.message)
    transpileErrorAction(err)
  })
  return mappedFiles
}

export const transpileSingleFile = async (
  path: string,
  data: string,
  project: Project
) => {
  if (path.endsWith(".json")) return "export default" + data

  try {
    const transpiled = await transpileBabelAsync(data, path)
    if (!transpiled?.code) throw new Error("Error while transpiling")

    const importsCode = changeImports(
      project.createSourceFile(path, transpiled.code, {
        overwrite: true,
      }),
      path
    )

    const finalCode = addCustomRefreshReg(importsCode, path)
    return finalCode
  } catch (err) {
    console.error(err.message)
    transpileErrorAction(err)
  }
}

const addCustomRefreshReg = (code: string, path: string) =>
  `
  function $RefreshReg$(c, id) {
    const path = "${path}"
    window.$RefreshRegGlobal$(c, path + '/' + id)
  }
  ` + code
