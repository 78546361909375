import { CodeRW } from "@iteria-app/react-lowcode/esm/io"
import {
  InspectedElement,
  LocaleMessageEditor,
  parseLocaleJSON,
} from "@iteria-app/react-lowcode/esm/localization"

export const editFormattedMessage = (
  target: HTMLElement,
  inspectedElement: InspectedElement,

  workbench: CodeRW
) => {
  const localeMessageEditor = new LocaleMessageEditor()

  localeMessageEditor.instrumentMessageHTML(
    target,
    inspectedElement as InspectedElement,
    event => {
      // const langLocale = getUserLocale()
      const langLocale = navigator.language?.indexOf("sk") >= 0 ? "sk" : "en"
      const localeSourceUrl = `/src/compiled-lang/${langLocale}.json`
      let data: string
      workbench.readFile(localeSourceUrl).then(originalLocaleStringJSON => {
        const originalMessages = parseLocaleJSON(
          originalLocaleStringJSON as string
        )
        const newValue = event.newMessageValue as string
        const messageId = event.messageId as string
        const found = originalMessages && originalMessages.find(message => message.id == messageId)
        // Return early if the field wasn't changed
        console.log(newValue, found?.value)
        if (newValue === found?.value) return

        if (originalMessages && found === undefined) {
          for (let i = originalMessages.length - 1; i >= 0; i--) {
            if (
              originalMessages[i].id ===
              originalMessages[originalMessages.length - 1].id
            ) {
              const before = originalLocaleStringJSON?.substring(
                0,
                originalMessages[i].position.end + 1
              )

              const after = originalLocaleStringJSON?.substring(
                originalMessages[i].position.end
              )
              const newRow = `"${messageId}"` + ":" + `"${newValue}"`
              originalLocaleStringJSON = before + "," + newRow + after
            }
          }
          data = originalLocaleStringJSON as string
        }
        if (originalMessages && found) {
          for (let i = originalMessages.length - 1; i >= 0; i--) {
            if (originalMessages[i].id === messageId) {
              const before = originalLocaleStringJSON?.substring(
                0,
                originalMessages[i].position.pos + 2
              )
              const after = originalLocaleStringJSON?.substring(
                originalMessages[i].position.end - 1
              )
              originalLocaleStringJSON = before + newValue + after
            }
          }
          data = originalLocaleStringJSON as string
        }
        workbench.writeFile(localeSourceUrl, data)
      })
    }
  )
}
