import { URI } from "vscode-uri"
import { frontendFileWriteAction } from "../messaging/actions"
import { VSCODE_IFRAME_ID } from "../util/constants"

export const transformObjectToUint8Array = (object: any) => {
  const data = Object.keys(object).map(k => object[k])
  const arr = new Uint8Array(data)
  return arr
}

export const notifyVSCodeFrontend = (path: string, data: string) => {
  const vscodeIframe = document.getElementById(
    VSCODE_IFRAME_ID
  ) as HTMLIFrameElement
  const { contentWindow } = vscodeIframe
  if (!contentWindow) {
    throw new Error("Unable to destructure window from Iframe")
  }
  // Change focus so changes in file will be immediately displayed
  vscodeIframe.focus()
  contentWindow.postMessage(
    {
      command: "iteria.refreshFile",
      payload: { uri: URI.parse(path), content: Buffer.from(data) },
    },
    "*"
  )
}

export const uint8ToString = (content: Uint8Array) => {
  const buffer = Buffer.from(content)
  return buffer.toString()
}

export const dispatchFrontendFileWriteAction = (
  uri: URI,
  content: Uint8Array
) => {
  const buffer = Buffer.from(content)
  const stringContent = buffer.toString()

  frontendFileWriteAction(uri.path, stringContent)
}
