import React from "react"

import "@material/mwc-button"
import "@material/mwc-dialog"
import { BackendErrors } from "../WorkbenchComponent"

export interface ErrorDialogProps {
  backendErrors: BackendErrors | null | undefined
  setErrorDialog: (errorDialog: boolean) => void
}

const ErrorDialog: React.FC<ErrorDialogProps> = ({
  backendErrors,
  setErrorDialog,
}) => (
  /* @ts-ignore */
  <mwc-dialog
    style={{ "--mdc-dialog-max-width": "auto" }}
    heading="Error info"
    open
  >
    <pre style={{ fontSize: 12 }}>
      {backendErrors?.bundlerError
        ? backendErrors.bundlerError.message
        : backendErrors?.transpilerError?.message}
    </pre>

    {/* @ts-ignore */}
    <mwc-button
      raised
      slot="primaryAction"
      dialogAction="accept"
      onClick={() => setErrorDialog(false)}
    >
      Ok
      {/* @ts-ignore */}
    </mwc-button>
    {/* @ts-ignore */}
  </mwc-dialog>
)

export default ErrorDialog
