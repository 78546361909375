import { SourceLineCol } from "@iteria-app/react-lowcode/esm/ast"
import {
  addColumn,
  addFormInput,
  deleteColumn,
} from "@iteria-app/react-lowcode/esm/codegen"
import { CodeRW } from "@iteria-app/react-lowcode/esm/io"
import { getGeneratesFileSourceFromConfig } from "../graphql/generateGraphqlTypesFile"


export async function tryAddColumnToDatatable(
  code: string,
  source: SourceLineCol,
  io: CodeRW,
  index: number,
  column: string,
  typename: string,
): Promise<string | undefined> {
  console.log("__typename: ",typename)

  let entitiesSource = await getGeneratesFileSourceFromConfig(io)

  return await addColumn(entitiesSource ?? "", io, source, {
    property: column,
    entityName: typename,
    index
  })
}

export async function tryAddFormWidget(
  code: string,
  source: SourceLineCol,
  io: CodeRW,
  index: number,
  column: string
): Promise<string | undefined> {
  let entitiesSource = await getGeneratesFileSourceFromConfig(io)

  return await addFormInput(entitiesSource ?? "", io, source, {
    property: column,
    entityName: "Customer",
    index
  })
}

export async function tryDeleteColumn(
  code: string,
  source: SourceLineCol,
  io: CodeRW,
  index: number
): Promise<string | undefined> {
  return await deleteColumn(io, source, { index })
}
