import { MessageType } from "./messageTypes"
import { RemoveEventListener } from "./messaging"

export type MessageCallback<T> = (payload: T) => void

export default class WindowMessaging {
  constructor(window: Window) {
    this.window = window
  }

  private window: Window

  addEventListener<T>(type: MessageType, cb: MessageCallback<T>) {
    const eventListenerCallback = (e: MessageEvent) => {
      if (e.data.type === type && e.source === window) cb(e.data.payload)
    }
    this.window.addEventListener("message", eventListenerCallback)

    return (() =>
      this.window.removeEventListener(
        "message",
        eventListenerCallback
      )) as RemoveEventListener
  }

  postMessage<T>(type: MessageType, payload: T) {
    // FIXME update message
    this.window.postMessage({ type, payload }, "*")
  }
}
