import { SourceLineCol } from "@iteria-app/react-lowcode/esm/ast"
import { useState, useEffect } from "react"

import { MessageCallback } from "."
import { messagingService } from "../App"
import { ErrorData } from "../types"
import {
  ChangeActiveFilePathData,
  FileWriteData,
  ProjectLoadedData,
} from "../workbench/Workbench"
import {
  BUNDLER_ERROR,
  BUNDLE_FILES,
  CHANGE_ACTIVE_FILE_PATH,
  CLONE_ELEMENT,
  CONNECT_DEVTOOLS,
  CONNECT_ELEMENT_HIGHLIGHT,
  FILE_WRITE,
  GENERATE_GRAPHQL,
  IFRAME_REFRESHED,
  NAVIGATE_TO_SOURCE_CODE,
  PERFORM_REACT_REFRESH,
  PROJECT_LOADED,
  REFRESH_IFRAME,
  REMOVE_ELEMENT,
  TRANSPILER_ERROR,
  TRANSPILE_FILES,
} from "./messageTypes"
import { RemoveEventListener } from "./messaging"

export const useFileWriteListener = (cb: MessageCallback<FileWriteData>) => {
  const [
    removeEventListener,
    setRemoveEventListener,
  ] = useState<RemoveEventListener>()

  useEffect(() => {
    const removeEventListener = messagingService.addEventListener<FileWriteData>(
      FILE_WRITE,
      cb
    )
    setRemoveEventListener(() => removeEventListener)
  }, [])

  return [removeEventListener]
}

export const useChangeActiveFilePathListener = (
  cb: MessageCallback<ChangeActiveFilePathData>
) => {
  const [
    removeEventListener,
    setRemoveEventListener,
  ] = useState<RemoveEventListener>()

  useEffect(() => {
    const removeEventListener = messagingService.addEventListener<ChangeActiveFilePathData>(
      CHANGE_ACTIVE_FILE_PATH,
      cb
    )
    setRemoveEventListener(() => removeEventListener)
  }, [])

  return [removeEventListener]
}

export const useConnectDevtoolsListener = (cb: MessageCallback<void>) => {
  const [
    removeEventListener,
    setRemoveEventListener,
  ] = useState<RemoveEventListener>()

  useEffect(() => {
    const removeEventListener = messagingService.addEventListener(
      CONNECT_DEVTOOLS,
      cb
    )
    setRemoveEventListener(() => removeEventListener)
  }, [])

  return [removeEventListener]
}

export const useConnectElementHighlightListener = (
  cb: MessageCallback<void>
) => {
  const [
    removeEventListener,
    setRemoveEventListener,
  ] = useState<RemoveEventListener>()

  useEffect(() => {
    const removeEventListener = messagingService.addEventListener(
      CONNECT_ELEMENT_HIGHLIGHT,
      cb
    )
    setRemoveEventListener(() => removeEventListener)
  }, [])

  return [removeEventListener]
}

export const useProjectLoadedListener = (
  cb: MessageCallback<ProjectLoadedData>
) => {
  const [
    removeEventListener,
    setRemoveEventListener,
  ] = useState<RemoveEventListener>()

  useEffect(() => {
    const removeEventListener = messagingService.addEventListener<ProjectLoadedData>(
      PROJECT_LOADED,
      cb
    )
    setRemoveEventListener(() => removeEventListener)
  }, [])

  return [removeEventListener]
}

export const useCloneElementListener = (cb: MessageCallback<SourceLineCol>) => {
  const [
    removeEventListener,
    setRemoveEventListener,
  ] = useState<RemoveEventListener>()

  useEffect(() => {
    const removeEventListener = messagingService.addEventListener<SourceLineCol>(
      CLONE_ELEMENT,
      cb
    )
    setRemoveEventListener(() => removeEventListener)
  }, [])

  return [removeEventListener]
}

export const useRemoveElementListener = (
  cb: MessageCallback<SourceLineCol>
) => {
  const [
    removeEventListener,
    setRemoveEventListener,
  ] = useState<RemoveEventListener>()

  useEffect(() => {
    const removeEventListener = messagingService.addEventListener<SourceLineCol>(
      REMOVE_ELEMENT,
      cb
    )
    setRemoveEventListener(() => removeEventListener)
  }, [])

  return [removeEventListener]
}

export const useNavigateToCodeListener = (
  cb: MessageCallback<SourceLineCol>
) => {
  const [
    removeEventListener,
    setRemoveEventListener,
  ] = useState<RemoveEventListener>()

  useEffect(() => {
    const removeEventListener = messagingService.addEventListener<SourceLineCol>(
      NAVIGATE_TO_SOURCE_CODE,
      cb
    )
    setRemoveEventListener(() => removeEventListener)
  }, [])

  return [removeEventListener]
}

export const useTranspileFilesListener = (cb: MessageCallback<void>) => {
  const [
    removeEventListener,
    setRemoveEventListener,
  ] = useState<RemoveEventListener>()

  useEffect(() => {
    const removeEventListener = messagingService.addEventListener<void>(
      TRANSPILE_FILES,
      cb
    )
    setRemoveEventListener(() => removeEventListener)
  }, [])

  return [removeEventListener]
}

export const useBundleFilesListener = (cb: MessageCallback<void>) => {
  const [
    removeEventListener,
    setRemoveEventListener,
  ] = useState<RemoveEventListener>()

  useEffect(() => {
    const removeEventListener = messagingService.addEventListener<void>(
      BUNDLE_FILES,
      cb
    )
    setRemoveEventListener(() => removeEventListener)
  }, [])

  return [removeEventListener]
}

export const useRefreshIframeListener = (cb: MessageCallback<void>) => {
  const [
    removeEventListener,
    setRemoveEventListener,
  ] = useState<RemoveEventListener>()

  useEffect(() => {
    const removeEventListener = messagingService.addEventListener<void>(
      REFRESH_IFRAME,
      cb
    )
    setRemoveEventListener(() => removeEventListener)
  }, [])

  return [removeEventListener]
}

export const usePerformReactRefreshListener = (cb: MessageCallback<void>) => {
  const [
    removeEventListener,
    setRemoveEventListener,
  ] = useState<RemoveEventListener>()

  useEffect(() => {
    const removeEventListener = messagingService.addEventListener<void>(
      PERFORM_REACT_REFRESH,
      cb
    )
    setRemoveEventListener(() => removeEventListener)
  }, [])

  return [removeEventListener]
}

export const useIframeRefreshedListener = (cb: MessageCallback<void>) => {
  const [
    removeEventListener,
    setRemoveEventListener,
  ] = useState<RemoveEventListener>()

  useEffect(() => {
    const removeEventListener = messagingService.addEventListener<void>(
      IFRAME_REFRESHED,
      cb
    )
    setRemoveEventListener(() => removeEventListener)
  }, [])

  return [removeEventListener]
}

export const useBundlerErrorListener = (cb: MessageCallback<ErrorData>) => {
  const [
    removeEventListener,
    setRemoveEventListener,
  ] = useState<RemoveEventListener>()

  useEffect(() => {
    const removeEventListener = messagingService.addEventListener<ErrorData>(
      BUNDLER_ERROR,
      cb
    )
    setRemoveEventListener(() => removeEventListener)
  }, [])

  return [removeEventListener]
}

export const useTranspilerErrorListener = (cb: MessageCallback<ErrorData>) => {
  const [
    removeEventListener,
    setRemoveEventListener,
  ] = useState<RemoveEventListener>()

  useEffect(() => {
    const removeEventListener = messagingService.addEventListener<ErrorData>(
      TRANSPILER_ERROR,
      cb
    )
    setRemoveEventListener(() => removeEventListener)
  }, [])

  return [removeEventListener]
}
